<template>
    <div>
        <!--Login Section-->
        <section class="login-section">
            <div class="auto-container">
                <div class="row clearfix">
                    <div
                        class="column col-md-8 offset-md-2 col-sm-12 col-xs-12"
                    >
                        <h2>حساب جديد</h2>

                        <!-- Register Form -->
                        <div class="login-form register-form">
                            <div class="col-lg-4 col-md-12 col-sm-12">
                                <!-- Edit profile photo -->
                                <div class="edit-profile-photo">
                                    <img
                                        :src="userData.image ?? defaultImage"
                                        onclick="document.getElementById('image').click()"
                                        alt="profile-photo"
                                    />
                                    <div class="change-photo-btn">
                                        <div class="photoUpload">
                                            <span
                                                ><i
                                                    class="la la-cloud-upload"
                                                ></i
                                            ></span>
                                            <input
                                                type="file"
                                                class="upload"
                                                id="image"
                                                @change="toBase64"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Login Form-->
                            <vee-form
                                @submit="register"
                                :validation-schema="ProfileFormValidation"
                            >
                                <div class="row">
                                    <!-- Form Group -->
                                    <div
                                        class="form-group col-lg-6 col-md-6 col-sm-12"
                                    >
                                        <label>الإسم</label>
                                        <Field
                                            v-model="userData.name"
                                            class="form-control"
                                            name="name"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="name"
                                        />
                                    </div>

                                    <!-- Form Group -->
                                    <div
                                        class="form-group col-lg-6 col-md-6 col-sm-12"
                                    >
                                        <label>الإيميل</label>
                                        <Field
                                            v-model="userData.email"
                                            class="form-control"
                                            name="email"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="email"
                                        />
                                    </div>

                                    <!-- Form Group -->
                                    <div
                                        id="mobile-group"
                                        class="form-group col-lg-6 col-md-6 col-sm-12"
                                    >
                                        <label>الموبايل</label>

                                        <Field
                                            name="mobile"
                                            type="text"
                                            id="mobile"
                                            @blur="setMobile"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="mobile"
                                        />
                                    </div>

                                    <div
                                        class="form-group col-lg-6 col-md-6 col-sm-12 d-none"
                                    >
                                        <label class="control-label"
                                            >النوع</label
                                        >
                                        <Field
                                            as="select"
                                            v-model="userData.gender"
                                            class="form-control"
                                            name="gender"
                                        >
                                            <option value="m">ذكر</option>
                                            <option value="f">أنثى</option>
                                        </Field>
                                        <ErrorMessage
                                            class="has-error"
                                            name="gender"
                                        />
                                    </div>

                                    <div
                                        class="form-group col-lg-6 col-md-6 col-sm-12"
                                    >
                                        <label>الدولة</label>
                                        <Field
                                            v-model="userData.country"
                                            class="form-control"
                                            name="country"
                                            type="text"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="country"
                                        />
                                    </div>

                                    <div
                                        class="form-group col-lg-6 col-md-6 col-sm-12"
                                    >
                                        <label>كلمة المرور</label>
                                        <Field
                                            v-model="userData.password"
                                            class="form-control"
                                            name="password"
                                            type="password"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="password"
                                        />
                                    </div>

                                    <div
                                        class="form-group col-lg-6 col-md-6 col-sm-12"
                                    >
                                        <label class="control-label"
                                            >نوع الحساب</label
                                        >
                                        <Field
                                            as="select"
                                            v-model="userData.type"
                                            class="form-control"
                                            name="type"
                                        >
                                            <option value="user">مستخدم</option>
                                            <option value="advertiser">
                                                معلن
                                            </option>
                                        </Field>
                                        <ErrorMessage
                                            class="has-error"
                                            name="type"
                                        />
                                    </div>

                                    <div
                                        class="form-group col-lg-6 col-md-6 col-sm-12"
                                    >
                                        <label>تأكيد كلمة المرور</label>
                                        <Field
                                            v-model="
                                                userData.password_confirmation
                                            "
                                            class="form-control"
                                            name="password_confirmation"
                                            type="password"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="password_confirmation"
                                        />
                                    </div>

                                    <!-- Form Group -->
                                    <div
                                        v-if="userData.type != 'user'"
                                        class="form-group col-lg-12 col-md-12 col-sm-12"
                                    >
                                        <label>بيانات التحويل البنكى</label>
                                        <Field
                                            v-model="userData.transfer_data"
                                            class="form-control"
                                            name="transfer_data"
                                            as="textarea"
                                        />
                                        <ErrorMessage
                                            class="has-error"
                                            name="transfer_data"
                                        />
                                    </div>
                                </div>
                                <div class="form-group text-right">
                                    <button
                                        class="theme-btn btn-style-one"
                                        type="submit"
                                        name="submit-form"
                                    >
                                        تأكيد
                                    </button>
                                </div>
                            </vee-form>
                        </div>
                        <!--End Register Form -->
                    </div>
                </div>
            </div>
        </section>
        <!--End Login Section-->
    </div>
</template>

<script>
import intlTelInput from "intl-tel-input";

import { Form, Field, ErrorMessage } from "vee-validate";
import ProfileFormValidation from "../../forms/profileFormValidation";

export default {
    name: "register",
    components: {
        "vee-form": Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            userData: {
                gender: "m",
            },
            defaultImage:
                process.env.VUE_APP_API_BASE_URL +
                "/../public/assets/images/default_avatar.png",
            iti: null,
        };
    },
    mounted() {
        this.$axios({
            url: "https://ipinfo.io?token=ecb5aa6b504804",
            withCredentials: false,
        })
            .then((response) => {
                let countryCode = response.data.country ?? "OM";
                this.initTelInput(countryCode);
                this.setMobile();
            })
            .catch(() => {
                this.initTelInput("OM");
                this.setMobile();
            });
    },
    methods: {
        initTelInput(countryCode) {
            this.iti = intlTelInput(document.querySelector("#mobile"), {
                separateDialCode: true,
                initialCountry: countryCode,
                utilsScript:
                    "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
            });

            document
                .querySelector("#mobile")
                .addEventListener("countrychange", () => {
                    this.setMobile();
                });
        },
        setMobile() {
            let countryData = this.iti.getSelectedCountryData();
            this.userData.country_code = "+" + countryData.dialCode;
            this.userData.country = countryData.name.replace(/\(.*\)/g, "");
            this.userData.mobile = this.iti.getNumber();
        },
        toBase64() {
            this.userData.image = document.querySelector("#image").files[0];

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(this.userData.image);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }).then((image) => {
                this.userData.image = image;
            });
        },
        register() {
            this.$store.dispatch("register", this.userData);
        },
    },
    computed: {
        ProfileFormValidation() {
            ProfileFormValidation.mobile = () => {
                if (!this.iti.isValidNumber()) {
                    return "رقم الهاتف غير صحيح";
                }

                return true;
            };
            return ProfileFormValidation;
        },
    },
};
</script>
<style>
#mobile-group .iti {
    display: block !important;
}

.iti--allow-dropdown .iti__flag-container,
.iti--separate-dial-code .iti__flag-container {
    direction: ltr !important;
}
</style>

<style scoped>
@import "./../../../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

#mobile {
    direction: ltr;
}

.rtl .login-form .form-group {
    text-align: right;
}

@media (min-width: 768px) {
    .offset-md-3 {
        margin-right: 25%;
    }

    .offset-md-2 {
        margin-right: 16.666667%;
    }
}

.edit-profile-photo {
    position: relative;
    height: auto;
    width: 250px;
    margin: 0 auto 50px;
}

.edit-profile-photo img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
    border: 2px solid #b9b9b9;
    cursor: pointer;
}

.change-photo-btn {
    position: absolute;
    display: inline-block;
    font-size: 14px;
    bottom: -15px;
    margin-left: -15px;
    left: 50%;
    width: auto;
    transition: 0.3s;
    font-weight: 500;
    cursor: pointer;
    line-height: 28px;
}

.photoUpload {
    position: relative;
    overflow: hidden;
    padding: 3px 12px;
    border-radius: 50px;
    cursor: pointer;
    font-size: 14px;
    background-color: #00c0ff;
}

.photoUpload span {
    color: #fff;
    font-weight: 600;
}

.photoUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}
</style>